import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { HttpClient,HttpHeaders } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../api.service';
import { environment } from '../../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import * as moment from "moment";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
	open = [1];
  	contactForm: FormGroup;
	submitted = false;
	optionRequete = {
	    headers: new HttpHeaders({
		    'Accept': 'application/json',
			'Content-Type': 'application/json',
		})
	};
	back_url: any;
	base_url: any;
	banner:string;
	title:string;
	pages = <any>[];
	page_elements = <any>[];
	offices_paragraph = <any>[];
	map_img: any;
	contact_paragraph = <any>[];
	contact_img: any;
	offices = <any>[];
	back_webform_url: any;
	lang: any;
	date: any;

  	constructor(private formBuilder: FormBuilder,
		private http: HttpClient,
		private toastr: ToastrService,
		private apiService: ApiService,
		private titleService: Title,
		private metaService: Meta,
		@Inject(DOCUMENT) private document: Document,
		private activatedRoute: ActivatedRoute) { }

	ngOnInit(): void {
		this.lang = this.activatedRoute.snapshot.params.lang;
		this.activatedRoute.paramMap.subscribe(params => {
		this.lang = 'en';
		});
		this.back_url = environment.back_url;
		this.base_url = environment.base_url;
		// Webservice to get pages(content type) from backoffice
		this.apiService.getPages().subscribe((data)=>{
			this.pages = data;
			this.pages = this.pages.filter(page => page.langcode[0].value === this.lang);
			for(let page of this.pages){
				// Get the content type page (Locations)
				if ((page.nid[0].value === 131) || (page.nid[0].value === 197)){
					this.titleService.setTitle(page.metatag.value.title);
					this.metaService.addTags([
					  { name: "description"      , content:page.metatag.value.description},
					  { name: "og:description"   , content:page.metatag.value.og_description},
					  { name: "og:url"           , content:this.document.location},
					  { name: "og:type"          , content:page.metatag.value.og_type},
					  { name: "og:title"         , content:page.metatag.value.og_title},
					  { name: "og_site_name"     , content:page.metatag.value.og_site_name},
					  { name: "og_image"         , content:this.base_url +'/assets/images/banner-og.png'},
					  { name: "fb:app_id"        , content:""}
					]);

					this.title = page.title[0].value;
					if(page.field_banner[0] && page.field_banner[0].uri[0] && page.field_banner[0].uri[0].url){
					this.banner = this.back_url+page.field_banner[0].uri[0].url;
					}
					this.page_elements.push(page.field_elements)
					for(let element of this.page_elements[0]){

					  this.offices_paragraph = this.page_elements[0].filter(p => p.type[0].target_id === 'image_left_text_right' && ((p.id[0].value === 58) || (p.id[0].value === 207)))
					  this.map_img = this.back_url + this.offices_paragraph[0].field_image[0].uri[0].url

					  this.contact_paragraph = this.page_elements[0].filter(p => p.type[0].target_id === 'image_left_text_right' && ((p.id[0].value === 59) || (p.id[0].value === 208)))
					  this.contact_img = this.back_url + this.contact_paragraph[0].field_image[0].uri[0].url
					}
				}
			}
		});
		// Webservice to get offices(content type) from backoffice
		this.apiService.getOffices().subscribe((data)=>{
			this.offices = data;
			this.offices = this.offices.filter(page => page.langcode[0].value === this.lang);
		});

		this.contactForm = this.formBuilder.group({
			name: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			subject: ['', Validators.required],
			message: ['', Validators.required],
			mobile: ['']
		});

	}

  	get f() { return this.contactForm.controls; }

	onSubmit() {
		this.submitted = true;
		this.back_webform_url = environment.back_webform_url;
	    this.date = moment().format('LL');
	    if (this.contactForm.controls['name'].valid
	    	&& this.contactForm.controls['email'].valid
	    	&& this.contactForm.controls['message'].valid) {

	      	this.http.post(this.back_webform_url,{
				webform_id: "contact_swicorp",
	        	language: this.lang,
	        	name: this.contactForm.value.name,
	        	email: this.contactForm.value.email,
	        	subject: this.contactForm.value.subject,
	        	message: this.contactForm.value.message,
	        	mobile: this.contactForm.value.mobile,
	        	date: this.lang === 'en' ? this.date : moment().locale("ar").format('LL'),
			}
			, this.optionRequete).subscribe(async (res: any) => {
				if(res && res.sid !== ''){
					if(this.lang === 'en'){
						this.toastr.info('Request sent successfully')
					} else{
						this.toastr.info('تم ارسال الطلب بنجاح')
					}
					this.contactForm.reset()
					this.onReset();
				} else {
					error => {
						if(this.lang === 'en'){
							this.toastr.info('Server Error')
						} else{
							this.toastr.info('خطأ في الإرسال')
						}
						this.contactForm.reset()
						this.onReset();
					}
				}

			},
			error => {
				console.log(error)
				if(this.lang === 'en'){
					this.toastr.info('Server Error')
				} else {
					this.toastr.info('خطأ في الإرسال')
				}
			})
		}
			else {
				if(this.lang === 'en'){
					this.toastr.info('Invalid Formulary')
				} else {
					this.toastr.info('طلب الإتصال غير صالح')
				}
			}
	}

  	onReset() {
	  	for (let name in this.contactForm.controls) {
	    	this.contactForm.controls[name].setErrors(null);
	  	}
	}

	getImageUri(url){
		if(url){
		  return this.back_url + url;
		}
	}
}
