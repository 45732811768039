import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {
  isVisible = true;
  currentLanguage: string = 'en';
  ngOnInit(): void {
      this.isVisible = true;
  }

  closeModal() {
    this.isVisible = false;
    document.body.classList.remove('no-scroll');
  }

  toggleLanguage() {
    this.currentLanguage = this.currentLanguage === 'en' ? 'ar' : 'en';
  }
}
