import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { NgxSpinnerModule } from "ngx-spinner";
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './partials/header/header.component';
import { FooterComponent } from './partials/footer/footer.component';
import { SliderComponent } from './partials/slider/slider.component';
import { CarrouselComponent } from './components/carrousel/carrousel.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { AboutComponent } from './pages/about/about.component';
import { TeamComponent } from './pages/team/team.component';
import { HomeComponent } from './pages/home/home.component';
import { MemberComponent } from './components/member/member.component';
import { InvestmentBankingComponent } from './pages/investment-banking/investment-banking.component';
import { AssetManagementComponent } from './pages/asset-management/asset-management.component';
import { PrivateEquityComponent } from './pages/private-equity/private-equity.component';
import { CmaDisclosuresComponent } from './pages/cma-disclosures/cma-disclosures.component';
import { CmaDisclosuresBloc1Component } from './components/cma-disclosures-bloc1/cma-disclosures-bloc1.component';
import { CmaDisclosuresBloc2Component } from './components/cma-disclosures-bloc2/cma-disclosures-bloc2.component';
import { CmaDisclosuresBloc3Component } from './components/cma-disclosures-bloc3/cma-disclosures-bloc3.component';
import { CmaDisclosuresBloc4Component } from './components/cma-disclosures-bloc4/cma-disclosures-bloc4.component';
import { CmaDisclosuresBloc5Component } from './components/cma-disclosures-bloc5/cma-disclosures-bloc5.component';
import { CmaDisclosuresBloc6Component } from './components/cma-disclosures-bloc6/cma-disclosures-bloc6.component';
import { CmaDisclosuresBloc7Component } from './components/cma-disclosures-bloc7/cma-disclosures-bloc7.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HistoryComponent } from './components/history/history.component';
import { InvestmentThemesComponent } from './pages/investment-themes/investment-themes.component';
import { AliancesComponent } from './pages/aliances/aliances.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CareersComponent } from './pages/careers/careers.component';
import { MediaCenterComponent } from './pages/media-center/media-center.component';
import { NewsCarrouselComponent } from './components/news-carrousel/news-carrousel.component';
import { MediaCarrouselComponent } from './components/media-carrousel/media-carrousel.component';
import { MediaCenterDetailComponent } from './pages/media-center-detail/media-center-detail.component';
import { NewsDetailComponent } from './pages/news-detail/news-detail.component';
import { ComplaintsComponent } from './pages/complaints/complaints.component';
import { NoSanitizePipe } from './safe.pipe';
import { Erreur404Component } from './pages/erreur404/erreur404.component';
import { AssetsCarrouselComponent } from './components/assets-carrousel/assets-carrousel.component';
import { SearchComponent } from './pages/search/search.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {DisclaimerComponent} from "./pages/disclaimer/disclaimer.component";

@NgModule({
  declarations: [
    NoSanitizePipe,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SliderComponent,
    CarrouselComponent,
    AboutComponent,
    TeamComponent,
    HomeComponent,
    PageTitleComponent,
    MemberComponent,
    InvestmentBankingComponent,
    AssetManagementComponent,
    PrivateEquityComponent,
    CmaDisclosuresComponent,
    CmaDisclosuresBloc1Component,
    CmaDisclosuresBloc2Component,
    CmaDisclosuresBloc3Component,
    CmaDisclosuresBloc4Component,
    CmaDisclosuresBloc5Component,
    CmaDisclosuresBloc6Component,
    CmaDisclosuresBloc7Component,
    HistoryComponent,
    InvestmentThemesComponent,
    AliancesComponent,
    ContactComponent,
    CareersComponent,
    MediaCenterComponent,
    NewsCarrouselComponent,
    MediaCarrouselComponent,
    MediaCenterDetailComponent,
    NewsDetailComponent,
    ComplaintsComponent,
    Erreur404Component,
    AssetsCarrouselComponent,
    SearchComponent,
    DisclaimerComponent
  ],
  imports: [
    RecaptchaModule,
    RecaptchaFormsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CarouselModule,
    AppRoutingModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
        timeOut: 3000,
        preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    MatButtonModule,
    MatMenuModule,
    MatSelectModule
  ],
  providers: [
    { provide: 'isBrowser', useValue: true }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
