<footer class="footer">
  <div class="container-fluid pt-5 pb-5">
    <div class="row">
      <div class="col-lg col-md-12 mb-4 text-center">
        <a [routerLink]="['/']">
          <img src="assets/images/logo.svg" class="img-fluid" alt="" />
        </a>
      </div>
      <div class="col-lg col-md-4">
        <!-- <p class="title">
					{{'TheFirm' | translate}}
					<span class="d-md-none" data-toggle="collapse" data-target="#collapse_footer_1" aria-expanded="false" aria-controls="collapse_footer_1"></span>
				</p> -->
        <ul id="collapse_footer_1">
          <!-- <li>
            <a [routerLink]="['about', LangcurrentURL]">{{
              "AboutSwicorp" | translate
            }}</a>
          </li> -->
          <li>
            <a [routerLink]="['/team']">{{
              "TheTeam" | translate
            }}</a>
          </li>
        </ul>
      </div>
      <div class="col-lg col-md-4">
        <!-- <p class="title">
					{{'Services' | translate}}
					<span class="d-md-none" data-toggle="collapse" data-target="#collapse_footer_4" aria-expanded="false" aria-controls="collapse_footer_4"></span>
				</p> -->
        <ul id="collapse_footer_4">
          <li>
            <a [routerLink]="['/investment']">{{
              "Investment" | translate
            }}</a>
          </li>
          <!-- <li>
            <a [routerLink]="['/investment-banking', LangcurrentURL]">{{
              "InvestmentBanking" | translate
            }}</a>
          </li> -->
          <!-- <li><a [routerLink]="['/asset-management', LangcurrentURL]">{{'AssetManagement' | translate}}</a></li> -->
          <!-- <li>
            <a [routerLink]="['/private-equity', LangcurrentURL]">{{
              "PrivateEquity" | translate
            }}</a>
          </li> -->
        </ul>
        <!-- <a
          class="title mt-4"
          [routerLink]="['/cma-disclosures', LangcurrentURL]"
          >{{ "CMA" | translate }}</a
        > -->
      </div>
      <!-- <div class="col-lg col-md-3">
				<a class="title" [routerLink]="['/investment-themes', LangcurrentURL]" routerLinkActive="active">
					{{'InvestmentThemes' | translate}}
					<span class="d-md-none" data-toggle="collapse" data-target="#collapse_footer_3" aria-expanded="false" aria-controls="collapse_footer_3"></span>
				</a>
				<ul class="collapse dont-collapse-sm" id="collapse_footer_3">
					<li><a [routerLink]="['/investment-themes', LangcurrentURL]" routerLinkActive="active">{{'NewEconomy' | translate}}</a></li>
					<li><a [routerLink]="['/investment-themes', LangcurrentURL]" routerLinkActive="active">{{'Education' | translate}}</a></li>
					<li><a [routerLink]="['/investment-themes', LangcurrentURL]" routerLinkActive="active">{{'ConsumerRetail' | translate}}</a></li>
					<li><a [routerLink]="['/investment-themes', LangcurrentURL]" routerLinkActive="active">{{'RenewableEnergy' | translate}}</a></li>
					<li><a [routerLink]="['/investment-themes', LangcurrentURL]" routerLinkActive="active">{{'RealEstate' | translate}}</a></li>
				</ul>
			</div> -->
      <div class="col-lg col-md-4">
        <!-- <p class="title">
					{{'ContactCareers' | translate}}
					<span class="d-md-none" data-toggle="collapse" data-target="#collapse_footer_2" aria-expanded="false" aria-controls="collapse_footer_2"></span>
				</p> -->
        <ul id="collapse_footer_2">
          <li>
            <a [routerLink]="['/contact']">{{
              "Contact" | translate
            }}</a>
          </li>
          <!-- <li>
            <a [routerLink]="['/careers', LangcurrentURL]">{{
              "Careers" | translate
            }}</a>
          </li> -->
        </ul>
        <!-- <a [routerLink]="['/media-center', LangcurrentURL]" class="title mt-4">
					{{'MediaCenter' | translate}}
					<span class="d-md-none" data-toggle="collapse" data-target="#collapse_footer_5" aria-expanded="false" aria-controls="collapse_footer_5"></span>
				</a> -->
        <!-- <a class="title mt-4" [routerLink]="['/complaints', LangcurrentURL]">{{
          "Complaints" | translate
        }}</a> -->
      </div>
    </div>
    <div class="container">
      <div class="social-media">
        <a
          href="https://www.linkedin.com/company/swicorp"
          target="_blank"
          class="social-link linkedin"
          ><i class="fa fa-linkedin fa-lg" aria-hidden="true"></i
        ></a>
        <a
          href="https://twitter.com/swicorpgroup"
          class="social-link twitter"
          target="_blank"
          ><i class="fa fa-twitter" aria-hidden="true"></i
        ></a>
        <a
          href="https://www.youtube.com/channel/UCNsvmmjj0dpF2pSsLS9oqhA"
          class="social-link youtube"
          target="_blank"
          ><i class="fa fa-youtube-play" aria-hidden="true"></i
        ></a>
        <a
          href="https://www.facebook.com/Swicorpgroup"
          class="social-link facebook"
          target="_blank"
          ><i class="fa fa-facebook" aria-hidden="true"></i
        ></a>
        <a
          href="https://www.instagram.com/swicorpgroup"
          class="social-link instagram"
          target="_blank"
          ><i class="fa fa-instagram" aria-hidden="true"></i
        ></a>
        <a
          href="https://www.euroquity.com/fr/entity/Swicorp-922c1f47-a1c3-442f-9855-7ff55d93a990/"
          class="social-link euroquity"
          target="_blank"
          ><img src="/assets/images/euroquity.svg" alt=""
        /></a>
        <a
          href="https://www.pinterest.com/swicorp"
          class="social-link pinterest"
          target="_blank"
          ><i class="fa fa-pinterest" aria-hidden="true"></i
        ></a>
        <a
          href="https://www.crunchbase.com/organization/swicorp"
          class="social-link crunchbase"
          target="_blank"
          ><img src="/assets/images/crunchbase.svg" alt=""
        /></a>
      </div>
    </div>
  </div>
  <div
    class="copyright bg-white text-dark pt-2 pb-2 text-center text-uppercase"
  >
    Copyright Swicorp © 2024 All Rights Reserved  </div>
</footer>
