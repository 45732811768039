import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from '../../api.service';
import { environment } from '../../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
  base_url:any;
  back_url: any;
  non_independent_board_members_type: any;
  executives: any;
  independent_board_members_type: any;
  teamMembers = <any>[];
  executives_board_members = <any>[];
  non_independent_board_members = <any>[];
  independent_board_members = <any>[];
  banner:string;
  title:string;
  pages = <any>[];
  page_elements = <any>[];


  constructor(private apiService: ApiService, private titleService: Title, private metaService: Meta,
    @Inject(DOCUMENT) private document: Document, private activatedRoute: ActivatedRoute ) { }

  ngOnInit(): void {
    let lang = this.activatedRoute.snapshot.params.lang;
    this.activatedRoute.paramMap.subscribe(params => {
      lang = 'en';
    });
    this.back_url = environment.back_url;
    this.apiService.getPages().subscribe((data)=>{
      this.pages = data;
      this.pages = this.pages.filter(page => page.langcode[0].value === lang);
      for(let page of this.pages){
        // Get the content type page (The Team)
        if ((page.nid[0].value === 128) || (page.nid[0].value === 191)){
          this.titleService.setTitle(page.metatag.value.title);
          this.metaService.addTags([
              { name: "title", content: page.metatag.value.title },
              { name: "description", content: page.metatag.value.description },
              { property: "og:title", content: page.metatag.value.og_title },
              { property: "og:description", content: page.metatag.value.og_description },
              { property: "og:url", content:this.document.location },
              { property: "og:type", content: 'website' },
              { property: "og:site_name", content: page.metatag.value.og_site_name },
              { property: "og:image", content:this.base_url +'/assets/images/banner-og.png' },
              { property: "og:image:url", content:this.base_url +'/assets/images/banner-og.png'},
              { property: "og:image:alt", content: 'Swicorp logo' },
              { name: "twitter:card", content: "summary" },
              { name: "twitter:url", content: this.document.location  },
              { name: "twitter:title", content: page.metatag.value.title },
              { name: "twitter:image", content:this.base_url +'/assets/images/banner-og.png' },
              { name: "twitter:image:width", content: "1280" },
              { name: "twitter:image:height", content: "1280" },
              { name: "twitter:image:alt", content: "Swicorp logo" },
          ]);
          this.title = page.title[0].value;
          if(page.field_banner[0] && page.field_banner[0].uri[0] && page.field_banner[0].uri[0].url){
           this.banner = this.back_url+page.field_banner[0].uri[0].url;
          }
           // Creating array of field_elements of the page(content type)
          this.page_elements.push(page.field_elements)
        }
      }
    });
    this.apiService.getTeamMembers().subscribe((data)=>{
      this.back_url = environment.back_url;
      this.teamMembers = data;
      this.teamMembers = this.teamMembers.filter(page => page.langcode[0].value === lang);
      let non_independent_board_members;
      non_independent_board_members = this.teamMembers.filter(p => p.field_membre_type[0].name[0].value === 'Non-Independent Board Members')
      non_independent_board_members.sort((a, b) => a.field_weight[0].value - b.field_weight[0].value)

      this.executives_board_members = this.teamMembers.filter(p => p.field_membre_type[0].name[0].value === 'Executives')
      this.executives_board_members.sort((a, b) => a.field_weight[0].value - b.field_weight[0].value)

      this.independent_board_members = this.teamMembers.filter(p => p.field_membre_type[0].name[0].value === 'Independent Board Members')
      this.independent_board_members.sort((a, b) => a.field_weight[0].value - b.field_weight[0].value)

      for (let non_independant of non_independent_board_members){

        this.non_independent_board_members_type = non_independant.field_membre_type[0].name[0].value
      }

      this.non_independent_board_members = non_independent_board_members;
      for (let executive_board_member of this.executives_board_members){

        this.executives = executive_board_member.field_membre_type[0].name[0].value
      }
      for (let independent_board_member of this.independent_board_members){

        this.independent_board_members_type = independent_board_member.field_membre_type[0].name[0].value
      }
    });
  }

  getImageUri(url){
    if(url){
      return this.back_url + url;
    }
  }
}
