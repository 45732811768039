import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutComponent } from './pages/about/about.component';
import { TeamComponent } from './pages/team/team.component';
import { HomeComponent } from './pages/home/home.component';

import { InvestmentBankingComponent } from './pages/investment-banking/investment-banking.component';
import { AssetManagementComponent } from './pages/asset-management/asset-management.component';
import { PrivateEquityComponent } from './pages/private-equity/private-equity.component';
import { CmaDisclosuresComponent } from './pages/cma-disclosures/cma-disclosures.component';
import { InvestmentThemesComponent } from './pages/investment-themes/investment-themes.component';
import { AliancesComponent } from './pages/aliances/aliances.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CareersComponent } from './pages/careers/careers.component';
import { MediaCenterComponent } from './pages/media-center/media-center.component';
import { MediaCenterDetailComponent } from './pages/media-center-detail/media-center-detail.component';
import { NewsDetailComponent } from './pages/news-detail/news-detail.component';
import { ComplaintsComponent } from './pages/complaints/complaints.component';
import { SearchComponent } from './pages/search/search.component';

import { CmaDisclosuresBloc1Component } from './components/cma-disclosures-bloc1/cma-disclosures-bloc1.component';
import { CmaDisclosuresBloc2Component } from './components/cma-disclosures-bloc2/cma-disclosures-bloc2.component';
import { CmaDisclosuresBloc3Component } from './components/cma-disclosures-bloc3/cma-disclosures-bloc3.component';
import { CmaDisclosuresBloc4Component } from './components/cma-disclosures-bloc4/cma-disclosures-bloc4.component';
import { CmaDisclosuresBloc5Component } from './components/cma-disclosures-bloc5/cma-disclosures-bloc5.component';
import { CmaDisclosuresBloc6Component } from './components/cma-disclosures-bloc6/cma-disclosures-bloc6.component';
import { CmaDisclosuresBloc7Component } from './components/cma-disclosures-bloc7/cma-disclosures-bloc7.component';

import { Erreur404Component } from './pages/erreur404/erreur404.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  // { path: 'about/:lang', component: AboutComponent },
  { path: 'team', component: TeamComponent },

  // { path: 'investment-banking/:lang', component: InvestmentBankingComponent },
  // { path: 'asset-management/:lang', component: AssetManagementComponent },
  // { path: 'private-equity/:lang', component: PrivateEquityComponent },
  // { path: 'media-center/:lang', component: MediaCenterComponent },
  // { path: 'media-center/:id/:lang', component: MediaCenterDetailComponent },
  // { path: 'news/:id/:lang', component: NewsDetailComponent },
  // { path: 'complaints/:lang', component: ComplaintsComponent },
  // {
  //   path: 'cma-disclosures/:lang',
  //   component: CmaDisclosuresComponent,
  //   children: [
  //     {
  //       path: '',
  //       component: CmaDisclosuresBloc1Component,
  //     },
  //     {
  //       path: 'annual-disclosures',
  //       component: CmaDisclosuresBloc2Component,
  //     },
  //     {
  //       path: 'annual-disclosures/board-of-directors-reports',
  //       component: CmaDisclosuresBloc3Component,
  //     },
  //     {
  //       path: 'annual-disclosures/pillar-3-annual-reports',
  //       component: CmaDisclosuresBloc4Component,
  //     },
  //     {
  //       path: 'annual-disclosures/financial-statements',
  //       component: CmaDisclosuresBloc5Component,
  //     },
  //     {
  //       path: 'investor-awareness',
  //       component: CmaDisclosuresBloc6Component,
  //     },
  //     {
  //       path: 'organization-chart',
  //       component: CmaDisclosuresBloc7Component,
  //     },
  //   ],
  // },
  // { path: 'investment-themes/:lang', component: InvestmentThemesComponent },
  { path: 'investment', component: AliancesComponent },
  { path: 'contact', component: ContactComponent },
  // { path: 'careers/:lang', component: CareersComponent },
  { path: 'search/:q', component: SearchComponent },
  { path: '**', component: Erreur404Component },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
