<div class="modal show" *ngIf="isVisible" style="display: block; background-color: rgba(0, 0, 0, 0.5);">
  <div class="modal-dialog modal-dialog-centered" style="margin: auto;">
    <div class="modal-content">
      <div class="modal-header border-0 bg-transparent">
        <button type="button" class="btn " (click)="toggleLanguage()">
          {{ currentLanguage === 'en' ? 'AR' : 'EN' }}
        </button>
        <button type="button" class="close" (click)="closeModal()">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body" [dir]="currentLanguage === 'ar' ? 'rtl' : 'ltr'">
        <div class="content-box p-3">
          <!-- English Content -->
          <p class="page-description" *ngIf="currentLanguage === 'en'">
            <strong>Disclaimer</strong><br>
            Please be advised that certain individuals may attempt to contact you by falsely claiming to be our employees, affiliates, agents, or representatives. Under this false pretence, they may seek to obtain personal information or solicit money or valuables by presenting to offer loan services or opportunities to buy, sell, invest, finance, or engage in other business activities, allegedly on our behalf. For instance, such individuals might invite you to apply for a personal loan. These fraudulent individuals may contact you via email, internet, text message, phone, or other means. These offers are fraudulent, as we do not provide such services, and we strongly urge you to ignore these communications.<br><br>
            If you receive a suspicious offer or communication, please contact us immediately at <a class="text-info" href="mailto:info@swicorp.com">info@swicorp.com</a> and report it to the police or relevant authorities. Swicorp Company and its affiliates or subsidiaries are not responsible or liable for any claims, losses, damages, expenses, or inconvenience resulting from interactions with such imposters.
          </p>

          <!-- Arabic Content -->
          <p class="page-description" *ngIf="currentLanguage === 'ar'">
            <strong>إخلاء مسؤولية</strong><br>
            يرجى مراعاة أنه قد يتم التواصل معكم من قبل عدد من الأشخاص المضللين يزعمون أنهم موظفين أو تابعين أو وكلاء أو ممثلين لنا. هذا الادعاء غير صحيح وقد يحاولون الحصول على معلومات شخصية أو طلب أموال أو أمور ثمينة مقابل الحصول على عروض ترويجية لشراء أو بيع أو استثمار أو تمويل أو الانخراط في فرص عمل أخرى نيابة عنكم. على سبيل المثال، هؤلاء الأشخاص قد يدعونكم للتقديم على تمويل شخصي. هؤلاء الأشخاص المدعين قد يتواصلون معكم عن طريق البريد الإلكتروني، أو الإنترنت، أو الرسائل النصية أو الهاتف أو أي وسائل أخرى. هذه العروض مضللة حيث إننا لا نقدم هذه الخدمات وننصحكم ونحثكم على تجاهل هذه المراسلات.<br><br>
            في حال استلمتم أي عرض أو تواصل مريب ويدعو للشك، نأمل منكم التواصل مباشرة معنا على البريد الإلكتروني <a class="text-info" href="mailto:info@swicorp.com">info@swicorp.com</a> والإبلاغ عنها إلى الجهات الأمنية والجهات المختصة. شركة سويكورب وشركاتها التابعة أو الفرعية ليست مسؤولة عن أي مطالبات، أو خسائر أو أضرار أو مصاريف أو غير ذلك الناتجة عن الاستجابة لهؤلاء المحتالين.
          </p>

        </div>
      </div>
      <div class="modal-footer border-0">
      </div>
    </div>
  </div>
</div>
