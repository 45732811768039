import { Component, OnInit, HostListener } from '@angular/core';
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  ActivatedRoute,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  showMenu: boolean = false;
  showSearch: boolean = false;
  headerClass: string = '';
  route: string;
  currentURL = '';
  LangcurrentURL: string;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.showMenu = false;
        document.getElementById('navbarToggle')?.classList.remove('show');
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        // Present error to user
        console.log(event.error);
      }
    });
  }

  // changeLang(language: string) {
  //   this.translate.use(language);
  //   location.reload();
  // }
  // useLanguage(language: string) {
  //   this.translate.use(language);
  // }
  ngOnInit(): void {}

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (verticalOffset > 50) {
      this.headerClass = 'bg-color';
    } else {
      this.headerClass = '';
    }
  }

  search(text) {
    this.router.navigate(['search/' + text]);
    this.showSearch = false;
  }
}
