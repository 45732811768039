<div
  class="pos-f-t menu {{ headerClass }}"
  [ngClass]="showMenu ? 'active' : ''"
>
  <div class="container-fluid pt-3 pb-3">
    <nav class="navbar navbar-light text-right">
      <div class="logo">
        <a [routerLink]="['/']">
          <img src="assets/images/logo.svg" class="img-fluid" alt="" />
        </a>
      </div>

      <div class="mobile">
        <div class="bloc-seach" *ngIf="showSearch">
          <div class="icon"><i class="fa fa-search"></i></div>
          <input
            #searchText
            (keyup.enter)="search(searchText.value)"
            placeholder="..."
            class="form-control"
            type="text"
          />
        </div>
        <div
          class="overlay-seach"
          *ngIf="showSearch"
          (click)="showSearch = false"
        ></div>
        <div
          (click)="showSearch = !showSearch"
          class="btn-search d-inline-block mr-4 cursor_pointer"
        >
          <i class="fa fa-search fa-lg"></i>
        </div>

        <!-- <div class="dropdown d-sm-inline-block">
          <div class="select-lang">
            <a
              href="/{{ language }}"
              *ngFor="let language of translate.getLangs()"
              #langSelect
              (click)="changeLang(language)"
              class="lang-{{ language }}"
            >
              {{ language }}
            </a>
          </div>
        </div> -->
        <div
          class="navbar-toggler d-inline-block"
          (click)="showMenu = !showMenu"
          data-toggle="collapse"
          data-target="#navbarToggle"
          aria-controls="navbarToggle"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>
    <div class="collapse" id="navbarToggle">
      <div class="row text-center justify-content-center">
        <!-- <div class="col-1"></div> -->
        <!-- <div class="col-12 col-sm-2"> -->
        <!-- <div class="title">{{'TheFirm' | translate}}</div> -->
        <!-- <a
            [routerLink]="['about', LangcurrentURL]"
            routerLinkActive="active"
            >{{ "AboutSwicorp" | translate }}</a
          >
        </div> -->
        <!--<div class="col-12 col-sm-2">
					 <div class="title">Investment themes</div>
					<a [routerLink]="['/investment-themes', LangcurrentURL]" routerLinkActive="active" class="title">{{'InvestmentThemes' | translate}}</a>
					<a [routerLink]="['/investment-themes', LangcurrentURL]" routerLinkActive="active">{{'NewEconomy' | translate}}</a>
					<a [routerLink]="['/investment-themes', LangcurrentURL]" routerLinkActive="active">{{'Education' | translate}}</a>
					<a [routerLink]="['/investment-themes', LangcurrentURL]" routerLinkActive="active">{{'ConsumerRetail' | translate}}</a>
					<a [routerLink]="['/investment-themes', LangcurrentURL]" routerLinkActive="active">{{'RenewableEnergy' | translate}}</a>
					<a [routerLink]="['/investment-themes', LangcurrentURL]" routerLinkActive="active">{{'RealEstate' | translate}}</a>
				</div>-->
        <div class="col-12 col-sm-2">
          <a
            [routerLink]="['team']"
            routerLinkActive="active"
            >{{ "TheTeam" | translate }}</a
          >
          <!-- <div class="title">{{ "Services" | translate }}</div> -->
          <!-- <a
            [routerLink]="['/investment-banking', LangcurrentURL]"
            routerLinkActive="active"
            >{{ "InvestmentBanking" | translate }}</a
          > -->
          <!-- <a [routerLink]="['/asset-management', LangcurrentURL]" routerLinkActive="active">{{'AssetManagement' | translate}}</a> -->
          <!-- <a
            [routerLink]="['/private-equity', LangcurrentURL]"
            routerLinkActive="active"
            >{{ "PrivateEquity" | translate }}</a
          > -->
        </div>
        <div class="col-12 col-sm-2">
          <!-- <a [routerLink]="['/media-center', LangcurrentURL]" class="title">{{'MediaCenter' | translate}}</a> -->
          <!-- <a
            [routerLink]="['/cma-disclosures', LangcurrentURL]"
            class="title"
            >{{ "CMA" | translate }}</a
          > -->
          <a
            [routerLink]="['/investment']"
            routerLinkActive="active"
            >{{ "Investment" | translate }}</a
          >
        </div>
        <div class="col-12 col-sm-2">
          <!-- <div class="title">{{ "ContactCareers" | translate }}</div> -->
          <a [routerLink]="['/contact']">{{
            "Contact" | translate
          }}</a>
          <!-- <a [routerLink]="['/careers', LangcurrentURL]">{{
            "Careers" | translate
          }}</a> -->
        </div>
      </div>
    </div>
  </div>
</div>
