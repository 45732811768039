<ngx-spinner
  bdColor="rgba(19, 109, 128,0.9)"
  size="medium"
  color="#fff"
  type="ball-grid-beat"
>
  <p style="font-size: 20px; color: white">{{'Loading' | translate}}</p>
</ngx-spinner>
<div class="content">
	<app-header></app-header>
	<router-outlet></router-outlet>
  <app-disclaimer></app-disclaimer>
</div>
<app-footer></app-footer>
